.dashboard-section-header {
  margin-bottom: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-section-header .title {
  font-weight: 600;
  font-size: 22px;
}

.dashboard-section-header .description {
  font-size: 14px;
}

.dashboard-section-header .manage {
  text-decoration: none;
  color: #516e73;
  font-weight: 500;
}

.dashboard-section-content.-profile {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
}

.dashboard-section-content.-subscription {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
}

.dashboard-section-content.-vehicle {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 24px;
}

@media (max-width: 1100px) {
    .dashboard-section-content.-profile {
      grid-template-columns: 1fr;
    }
}


@media (max-width: 1100px) {
    .dashboard-section-content.-subscription {
      grid-template-columns: 1fr;
    }
}
