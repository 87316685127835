.vehicle-list .intro {
  margin-bottom: 1.5rem;
}

.vehicle-list .vehicle-card-header {
  background: var(--rs-gray-300);
  font-family: "Pathway Gothic One", sans-serif;
  font-size: 40px;
  text-align: center;
  padding: 20px;
}

.vehicle-list .vehicle-card-header span {
  display: inline-block;
  text-align: center;
  min-width: 0.45em;
}

.vehicle-list .vehicle-card-descrip {
  margin-bottom: 8px;
  font-size: 20px;
}

.vehicle-list .vehicle-card-descrip div{
  min-height: 24px;
}

.vehicle-list .vehicle-card-descrip div.vehicle-detail{
  font-size: 14px; 
  margin-top: 4px;
  margin-bottom: 4px;
  text-transform: capitalize
}

.vehicle-list .vehicle-card-descrip div.vehicle-detail-header{
  font-size: 18px;
  text-transform: uppercase;
  margin-top: 4px;
  margin-bottom: 4px;
}
