.dashboard-carpark-card {
  padding: 25px 23px;
  border: 1px solid var(--theme-primary-navy);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
}

.dashboard-carpark-card .title {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
  inline-size: 50%;
}

.dashboard-carpark-card .subtitle {
  color: #516e73;
  font-weight: 500;
  font-size: 14px;
}

.dashboard-carpark-card.-active {
  border-color: #19d8c8;
}

.dashboard-carpark-card.-offline {
  border-color: #ef1000;
}
.rs-tag-green {
  background-color: #19d8c8 !important; 
}

.profile-card-complete-tick {
  display: flex;
  color: #19d8c8;
  font-weight: 600;
  font-size: 15px;
  align-items: center;
}

.profile-card-complete-tick .tick {
  margin-right: 8px;
  height: 38px;
  width: 38px;
}

.dashboard-carpark-card .bottom {
  margin-top: 14px;
  display: flex;
}

.dashboard-carpark-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
  height: 64px;
}

.dashboard-carpark-capacity-title {
  text-align: center;
  margin-bottom: 4px;
}

.dashboard-carpark-usage-title {
  text-align: center;
}