.vehicle-search-details  .rs-panel-header {
  padding-bottom: 8px;
  padding-top: 4px; 
}
  

@media only screen and (max-width: 800px) {
    .rs-panel-title {
        margin-left: 40px !important;
    }
  }

  .hg-theme-default {
    background-color: #fff;
    font-family: "Public Sans", sans-serif;
    margin-top: 8px;
  }

  .inactive {
    display: none;
  }
  
  .hg-button {
    min-width: 48px;
    min-height: 48px;
    font-size: 1rem;
    border: 1px solid rgb(44, 44, 44);
    border-radius: 3px;
  }

  .result-panel > h2 {
    margin-left: 0px !important;
  }
  
  .result-panel > div > h2 {
    margin-left: 0px !important;
  }