body {
  margin: 0;
  font-family: 'acumin-pro', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'tnum';
  font-variant-numeric: tabular-nums;
}

html,
body,
#root > main > .rs-container {
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1.rs-panel-title,
h2.rs-panel-title,
h3.rs-panel-title,
.rs-panel-header h1,
.rs-panel-header h2,
.rs-panel-header h3 {
  font-weight: normal;
}

h1.rs-panel-title,
.rs-panel-header h1 {
  font-size: 2em;
}

h2.rs-panel-title,
.rs-panel-header h2 {
  font-size: 1.5em;
  line-height: 1.2;
  margin-top: 10px;
}

h3.rs-panel-title,
.rs-panel-header h3 {
  font-size: 1.2em;
}

.rs-panel-header {
  display: flex;
}

.rs-panel-body {
  padding: 10px;
  padding-left: 20px;
}

.rs-panel-header .rs-btn-toolbar {
  margin-top: 5px;
  margin-bottom: -5px;
}

.side-menu .rs-dropdown-menu {
  font-size: 16px;
}
.rs-panel-header .rs-btn-toolbar .rs-btn:not(.rs-btn-icon) {
  padding: 6px 12px;
  border-radius: 8px;
  position: relative;
  top: 3px;
}

.rs-panel-header .rs-btn-toolbar .rs-btn-primary {
  background: var(--theme-bg-primary-inverted);
  color: var(--theme-text-primary-inverted);
}

main p,
main ul,
main ol {
  max-width: 700px;
}

.rs-form-group {
  max-width: 600px;
}

.celllink.rs-table-cell .rs-table-cell-content {
  text-overflow: clip;
}

.celllink .rs-btn-subtle,
.celllink .rs-btn-link {
  font-weight: normal;
  color: var(--theme-text-primary);
}

.rs-form-control > * {
  width: 100%;
}

.rs-btn:not(.rs-picker-toggle) {
  border-radius: 10px;
}

.rs-btn-primary,
.rs-btn-default {
  text-transform: uppercase;
  font-weight: 500;
  padding-left: 20px;
  padding-right: 20px;
}

.rs-picker-input .rs-picker-toggle {
  text-transform: initial;
  font-weight: initial;
}

.rs-picker {
  display: block;
}

.rs-btn-subtle,
.rs-btn-link {
  font-weight: bold;
  color: var(--theme-text-primary);
}

.rs-btn-subtle:hover,
.rs-btn-link:hover {
  color: inherit;
}

.rs-btn-link,
.rs-btn-link:hover {
  text-decoration: none;
}

.rs-panel {
  overflow: visible;
}

.noover .rs-panel-header {
  padding-top: 0px;
}

.noover-steps { 
  display: flex;
  padding: 20;
  max-width: 600px;
}

.noover-steps button {
  margin-left: 20px;
  margin-right: 20px;
}

@media (max-width: 600px) {
  .rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-right {
    padding: 2px 0px 2px 8px;
  }
  .rs-btn-icon-with-text.rs-btn > .rs-icon {
    width: 32px;
    height: 36px;
  }
  .rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-left {
    padding: 2px 8px 2px 0px;
  }
  .rs-btn-icon-with-text.rs-btn > .rs-icon {
    width: 32px;
    height: 36px;
  }
}

.rs-form-vertical .rs-form-group .rs-form-control-label {
  display: inline-block;
}

.rs-form-vertical .rs-form-group .rs-form-control-label::after {
  content: ':';
}

.rs-form-control-label + .rs-form-help-text-tooltip {
  margin-top: 0;
}

.rs-form-control-label {
  font-weight: 500;
}

.rs-form:not(.rs-form-inline) .rs-form-group:not(:last-child) {
  margin-bottom: 16px;
}

p + form,
p + .rs-table {
  margin-top: 24px;
}

.rs-dropdown .rs-btn-default {
  text-transform: none;
}

.rs-table .rs-btn > .rs-dropdown-toggle-icon {
  margin: 0;
}

.rs-table .rs-table-cell-header .rs-table-cell-content .rs-form-help-text-tooltip {
  margin-bottom: 0;
  margin-top: 0;
  position: relative;
  top: 4px;
}

.noover-steps {
  padding-top: 20px;
  padding-bottom: 40px;
}

.rs-panel-title {
  font-weight: 700;
}

.rs-picker-select, .rs-picker-value-list{
  margin-top: 4px;
}

.rs-input-number.rs-input-group,  .rs-picker.rs-picker-toggle-wrapper{
  margin-top: 4px;
  height: 36px;
}

.rs-table-cell-content > .rs-input-number.rs-input-group,  .rs-table-cell-content >  .rs-picker.rs-picker-toggle-wrapper{
  margin-top: 0px;
}

.rs-input-group.rs-input-group-inside > .rs-input  {
  margin-bottom: 0px;
}

.rs-picker 
.rs-btn-primary,
.rs-btn-default {
  text-transform: none;
}
.summaryColumnFixed {
  font-weight: 700;
}
 
.rs-input-number.rs-input-group > .rs-input {
  margin-bottom: 0px;
  top: -2px;
}

.rs-input-group.rs-input-group-inside   {
  margin-bottom: 20px;
}

.rs-input, .rs-input-number, .rs-radio-group, .rs-picker {
  /* margin-bottom: 20px; */
}
.rs-form-control-label button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin-top: 4px;
}
.rs-steps {
  min-height: 30px;
}
.rs-steps-item {
  padding-left: 40px;
  position: relative;
  overflow: hidden;
}
.rs-steps-item-icon-wrapper {
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 16px;
  line-height: 1.75;
  color: #8e8e93;
  color: var(--rs-text-secondary);
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #8e8e93;
  border: 1px solid var(--rs-steps-border);
  border-radius: 50%;
}
.rs-steps-item-status-finish .rs-steps-item-icon-wrapper {
  border-color: var(--rs-text-secondary)!important;
  background-color: #61F1E3!important;
  color:var(--rs-text-invert)!important;
  color: var(--rs-steps-state-finish);
}
.rs-steps-item-status-error .rs-steps-item-icon-wrapper {
  border-color: #f44336;
  border-color: var(--rs-steps-state-error);
  color: #f44336;
  color: var(--rs-steps-state-error);
}
.rs-steps-item-status-process .rs-steps-item-icon-wrapper {
  border-color: #61F1E3;
  border-color: var(--rs-text-secondary)!important;
  background-color: #61F1E3!important;
  background-color: var(--rs-steps-state-process);
  color: #f7f7fa;
  color: var(--rs-text);
}
 .rs-steps-item-status-process .rs-steps-item-icon-wrapper .rs-icon {
  color: #61F1E3!important;
  color: var(--rs-steps-icon-state-process);
}
.rs-steps-item-icon-wrapper.rs-steps-item-custom-icon {
  border: none;
  background: none;
}
 .rs-steps-item-icon-wrapper > .rs-steps-item-icon {
  width: 100%;
  display: block;
  text-align: center;
  position: relative;
}
.rs-steps-item-icon-wrapper > .rs-steps-item-icon .rs-icon {
  font-size: 14px;
  vertical-align: middle;
}
 .rs-steps-item-content {
  color: #8e8e93;
  color: var(--rs-text-secondary);
  display: inline-block;
  position: relative;
  width: 100%;
}
 .rs-steps-item-status-process .rs-steps-item-content {
  color: #575757;
  color: var(--rs-text-primary);
}
 .rs-steps-item-status-error .rs-steps-item-content {
  color: #f44336;
  color: var(--rs-text-error);
}

.rs-steps-item-tail,
.rs-steps-item-title::after {
  position: absolute;
  border-color: #8e8e93;
  border-color: var(--rs-steps-border);
}
.rs-steps-item-status-process .rs-steps-item-tail,
.rs-steps-item-status-process .rs-steps-item-title::after {
  border-color: #8e8e93;
  border-color: var(--rs-steps-border);
}
.rs-steps-item-status-finish .rs-steps-item-tail,
.rs-steps-item-status-finish .rs-steps-item-title::after {
  border-color: #61F1E3!important;
  border-color: var(--rs-steps-state-finish);
}
.rs-steps-next-error .rs-steps-item-tail,
.rs-steps-next-error .rs-steps-item-title::after {
  border-color: #f44336;
  border-color: var(--rs-steps-border-state-error);
}





/** Admin dashboard **/
.chart-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 450px);
  gap: 2rem;
}

.chart-container {
  text-align: center;
}