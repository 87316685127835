.payments-panel .rs-panel-title {
  font-size: 1.2em;
  font-weight: 400;
}

.payment-header {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
}

.payment-title {
  font-size: 1.2em;
  line-height: 1.2;
  margin-top: 10px;
}
 
.payment-header div {
   margin: 4px; 
}

.payment-card-holder {
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
}

.payment-card-holder p {
  margin-bottom: 0px!important;
}

.payment-card-group {
  flex-direction: row;
  -webkit-column-gap: 25px;
  column-gap: 25px;
  display: flex; 
}

.payment-card-number {
  flex-basis: auto;
}

.payment-card-expiry {
  width: 150px
}

.payment-card-code {
  width: auto
}

.payment-card-number {
  padding-top: 24px;
}

@media only screen and (max-width: 800px) {
  .payments-panel .rs-panel-title {
      margin-left: 0px !important;
  }
}